import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import {
    BrowserRouter, Routes,
    Route,
} from "react-router-dom";

import {ActivationForm, ActivationSuccess, ActivationFail } from './ActivationForm';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ActivationForm/>} />
                <Route path="/i/:uid" element={<ActivationSuccess/>} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
);
