import React, {useState, useEffect} from 'react';
import {Col, Button, Row, Image, Container, Form} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import {isValidPhoneNumber as libraryPhoneValidation} from 'libphonenumber-js';
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios'
import {useNavigate, useParams} from "react-router-dom";
import backgroundImage from "./img/backgroundImage3.jpg";
import logoImage from "./img/cardiehLogo.jpg";

export function ActivationHeader() {
    return (
        <Row className="header mb-3 justify-content-end">
            <div className="col-2">
                <img src="/cardiehLogoTransparent2.png" className="img-fluid rounded"/>
                {/*<img src="/rectangleTransparent50.png"/>*/}
                {/*<img style={{ backgroundImage: `url(${backgroundImage})` }}></img>*/}
            </div>
        </Row>
    );
}

export function ActivationFooter() {
    return (
        <Row className="footer mt-5 text-muted">
            <div className="col-lg-4">
                &copy; 2022 SOMECOMPANY
            </div>
        </Row>
    );
}

export function ActivationSuccess() {

    let {uid} = useParams();

    const [card, setCard] = useState( '' )
    const [description, setDescription] = useState( '' )
    const [content, setContent] = useState( '' )
    const [loaded, setLoaded] = useState( false )

    useEffect( () => {
        if ( !loaded ) {
            axios.get( '/api?uuid=' + uid )
                .then( value => {
                    if ( value.status === 200 && value.data[0].card_number ) {
                        const cd = value.data[0];
                        const desc = JSON.parse( cd.description )
                        setCard( cd )
                        setLoaded( true )
                        setContent( desc.content )
                        console.log( desc.content )
                        setDescription( desc )
                        // this.setState( {mode: 'card', token: value.data.token, loginMessage: value.data.loginMessage} )
                        //this.setState( {loggedIn: value.data.loggedIn, token: value.data.token, loginMessage: value.data.loginMessage} )
                    }
                    console.log( value )
                } )
                .catch( reason => {
                    console.log( reason )
                    //this.setState( {loggedIn: false, token: '', loginMessage: '' + reason} )
                } );
        }
    } );


    return (
        <Container>
            <ActivationHeader/>
            {loaded &&
            <>
                <Row>
                    <Col className="col-lg-12">
                        <Image src={card.image} className="img-fluid  float-start" fluid={true}/>
                        <Col>
                            <h2>
                                {card.name}<br/>
                                {card.card_number_text} {card.card_number}<br/>
                                {/*{card.pin_text} {card.pin_code}*/}
                            </h2>
                        </Col>
                    </Col>

                </Row>
                {
                    content.map( ( d ) =>
                        <Row key={d.title}>
                            <div>
                                <h4>{d.title}</h4>
                                <div dangerouslySetInnerHTML={{__html: d.description}}></div>
                            </div>
                        </Row>
                    )
                }
            </>
            }
            {/*<ActivationFooter/>*/}
        </Container>
    )
}

export function ActivationFail() {
    return (
        <Container>
            <ActivationHeader/>
            <h2>Activation failed</h2>
            {/*<ActivationFooter/>*/}
        </Container>
    )
}

export function ActivationForm() {

    const [email, setEmail] = useState( '' );
    const [phone, setPhone] = useState( '' );
    const [country, setCountry] = useState( '' );
    const [code, setCode] = useState( '' );
    const [validated, setValidated] = useState( false );


    const handleEmailInput = e => {
        setEmail( e.target.value )
    };
    const handleCodeInput = e => {
        setCode( e.target.value )
    };
    const handlePhoneInput = ( value, country, e, formattedValue ) => {
        setPhone( value )
        setCountry( country )
    };

    let navigate = useNavigate();

    const phoneValidationMethod = ( phone, countryCode ) => {
        if ( phone === '' && countryCode === '' ) {
            return true;
        }
        const valid = libraryPhoneValidation( phone, countryCode );
        return valid;
    }

    const handleSubmit = ( event ) => {

        const form = event.currentTarget;
        const v = form.checkValidity();

        event.preventDefault();
        event.stopPropagation();

        setValidated( true );

        if ( v ) {
            const params = new URLSearchParams();
            params.append( 'code', code )
            params.append( 'email', email )
            params.append( 'phone', phone )
            axios.post( '/api', params )
                .then( value => {
                    if ( value.status === 200 && value.data.valid ) {
                        navigate( '/i/' + value.data.uid )
                    }
                    // console.log( value )
                } )
                .catch( reason => {
                    console.log( reason )


                    //this.setState( {loggedIn: false, token: '', loginMessage: '' + reason} )
                } );
        }
    }


    return (
        <div style={{
            backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
            width: '100vw', height: '100vh'
        }}>
            {/*<div className="d-lg-none col-2">
                hide on lg and wider screens
                <img src="/cardiehLogoTransparent.png" className="img-fluid rounded" />
            </div>*/}
            {/* <div className="d-none d-lg-block">hide on screens smaller than lg</div>*/}
            <Container style={{height: '100vh'}}>
                <ActivationHeader/>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row style={{height: '60vh'}} className="align-items-center" controlId="mainRow">
                        <Col className="mb-3 col-md-6 col-12">
                                <div id="companyInfo">
                                    <p className="fs-1 mb-5 fw-bold">
                                        GET IN TOUCH
                                    </p>
                                    <p className="fs-5">
                                         Address: No. 1206, Utrecht, The Netherlands
                                    </p>
                                    <p className="fs-5 lh-sm">
                                        <span>
                                            Primieh B.V.
                                        </span>
                                            <br/>
                                        <span>
                                            Company Registration Number RSIN: 858694
                                        </span>
                                    </p>
                                    <p className="fs-5">
                                        Support@cardieh.com
                                    </p>
                                </div>
                        </Col>
                        <Col className="mb-3 col-md-4 col-10">
                            <Form.Group as={Row} controlId="phoneInputField" className="mb-3">
                                <Form.Label>Please enter your mobile number</Form.Label>
                                <ReactPhoneInput
                                    inputClass={"w-100"}

                                    dropdownStyle={{
                                        backgroundColor: "rgba(245,245,245,0.35)",
                                        border: "none"
                                    }}
                                    inputStyle={{
                                        backgroundColor: "rgba(245,245,245,0.35)",
                                        border: "none"
                                    }}
                                    buttonStyle={{
                                        backgroundColor: "rgba(245,245,245,0.35)",
                                        border: "none"
                                    }}
                                    searchStyle={{
                                        backgroundColor: "rgba(245,245,245,0.35)",
                                        border: "none"
                                    }}

                                    country={'ae'}
                                    preferredCountries={['ae']}
                                    onlyCountries={['ae']}
                                    isValid={( value, country ) => phoneValidationMethod( value, country )}
                                    disableDropdown={true}
                                    value={phone}
                                    onChange={handlePhoneInput}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your mobile number correctly
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    onChange={handleEmailInput}
                                    value={email}
                                    type="email"
                                    name="email"
                                    required
                                    placeholder="Your e-mail*"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your email correctly
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="activationCode">
                                <Form.Label>Activation code</Form.Label>
                                <Form.Control
                                    onChange={handleCodeInput}
                                    value={code}
                                    type="text"
                                    name="code"
                                    isValid={false}
                                    required
                                    placeholder="Please enter your code"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your code correctly
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Button type="submit">
                                    Submit
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                {/*<ActivationFooter/>*/}
            </Container>
        </div>
    );
}

